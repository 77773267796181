/* HEEBO FONT IMPORT */
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* CORMORAT FONT IMPORT */
@import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&family=Heebo:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "Quentin";
  src: url("../public/fonts/Quentin.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.inp-number input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quentin {
  font-family: "Quentin", serif !important;
  font-optical-sizing: auto !important;
  font-weight: 400 !important;
  font-style: normal !important;
  color: #6b5c45 !important;
}
.cormorant {
  font-family: "Cormorant", serif !important;
  font-optical-sizing: auto !important;
  font-weight: 400 !important;
  font-style: normal !important;
  word-wrap: break-word;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}
.cormorant-title {
  font-family: "Cormorant", serif !important;
  font-optical-sizing: auto !important;
  font-weight: 400 !important;
  font-style: normal !important;
}
.cormorant-title-bold {
  font-family: "Cormorant", serif !important;
  font-optical-sizing: auto !important;
  font-weight: 800 !important;
  font-style: normal !important;
}
.heebo {
  font-family: "Heebo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

a,
p,
i {
  font-family: "Heebo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #0000008a;
}

/* FONT-SETTINGS-FINISHED */

/* SWIPER-CARD-STARTED */
.container {
  cursor: url("https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png")
      39 39,
    auto;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  overflow: hidden;
}
.deck {
  position: absolute;
  width: 300px;
  height: 200px;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
}

.deck > div {
  background-color: white;
  background-size: auto 85%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 45vh;
  max-width: 250px;
  height: 85vh;
  max-height: 485px;
  will-change: transform;
  border-radius: 10px;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4),
    0 10px 10px -10px rgba(50, 50, 73, 0.3);
}

@keyframes blinkingText {
 0%, 100% {
    opacity: 1
  }
  50% {
    opacity: 0
  }
}

.blinking-text {
  animation: blinkingText 3s infinite;
}

/* SWIPER-CARD-FINISHED */

/* RING-LOADING */
.ring-container {
  position: relative;
  width: 100px;
  height: 100px;
}
.ring {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid #00ffea;
  border-radius: 50%;
  animation: pulse 2s infinite ease-in-out;
  opacity: 0;
}
.ring:nth-child(2) {
  animation-delay: 0.5s;
  border-color: #ff4d6d;
}
.ring:nth-child(3) {
  animation-delay: 1s;
  border-color: #ffc107;
}
.ring:nth-child(4) {
  animation-delay: 1.5s;
  border-color: #40c057;
}
@keyframes pulse {
  0% {
    transform: scale(.3);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

/* SIDEBAR */
.sidebar::-webkit-scrollbar {
  display: none;
}
.sidebar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* swiper button */
.swiper-button-prev,
.swiper-button-next{

  color: #a6a6a8 !important;
}
